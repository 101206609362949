// Step 1: Import your component
import * as React from 'react';
import Layout from '../components/layout';
import { article } from '../styles/layout.css';
import { p, h2 } from '../styles/global.css';
import { StaticImage } from 'gatsby-plugin-image';

// Step 2: Define your component
const AboutPage = () => (
  <Layout pageTitle="About Me">
    <article className={article}>
      <h2 className={h2}>From USA to Japan, from dialup to fiber...</h2>
      <p className={p}>
        I've been around the world, and around the web. I've worked on design, code, and branding. Name something and i've probably done it for startups over the last 15 years.
      </p>
      <p className={p}>
        I enjoy bonsai, ultimate disc (frisbee), and DIY projects such as 3D printing custom computer keyboards.
      </p>
      <p className={p}>
        I love to grow and to help others grow.
      </p>
      <StaticImage src="../images/IMG_6787.JPG" alt="ben foden smiling, wearing sunglasses on a sunny day" placeholder="dominantColor" width={600} height={600} />

    </article>
  </Layout>
);

// Step 3: Export your component
export default AboutPage;
